<template>
    <v-card>
        <v-toolbar class="darken-1" color="blue" dark>
            <v-toolbar-title>
                Manual Upload
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn @click="dialogueClose" dark text>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <!--            <v-row dense>-->
        <!--                <v-col class="col-border" cols="4" :key="indexUpload" v-for="(upload,indexUpload) in uploadData">-->
        <!--                    <v-text-field label="Label" v-model="upload.label"/>-->
        <!--                    <div class="uploadGrid">-->
        <!--                        <v-file-input-->
        <!--                                :label="$t('file_input')"-->
        <!--                                :placeholder="$t('upload_manual')"-->
        <!--                                accept="doc,docx,pdf"-->
        <!--                                prepend-icon="mdi-paperclip"-->
        <!--                                v-model="upload.manual"-->
        <!--                        >-->
        <!--                            <template v-slot:selection="{ text }">-->
        <!--                                <v-chip-->
        <!--                                        color="primary"-->
        <!--                                        label-->
        <!--                                        small-->
        <!--                                >-->
        <!--                                    {{ text }}-->
        <!--                                </v-chip>-->
        <!--                            </template>-->
        <!--                        </v-file-input>-->
        <!--                    </div>-->

        <!--                    <v-btn v-if="uploadData.length >1" @click="removeUploadData(indexUpload)">-->
        <!--                        <v-icon>mdi-minus</v-icon>-->
        <!--                    </v-btn>-->
        <!--                </v-col>-->
        <!--            </v-row>-->
        <v-row dense>
            <v-col
                    :key="indexUpload"
                    cols="4"
                    v-for="(upload,indexUpload) in uploadData"
            >
                <v-card class="col-border pa-2 ma-3">
                    <v-autocomplete
                            v-model="upload.FileName"
                            :items="labelDropdownUpload"
                            dense
                            item-text="value"
                            item-value="value"
                            label="label"
                    ></v-autocomplete>
                    <div class="uploadGrid">
                        <v-file-input
                                :label="$t('file_input')"
                                :placeholder="$t('upload_manual')"
                                accept="application/pdf"
                                prepend-icon="mdi-paperclip"
                                v-model="upload.File"
                        >
                          
                        </v-file-input>
                    </div>
                    <v-card-actions>
                        <v-btn color="red"  dark @click="removeUploadData(indexUpload)" v-if="uploadData.length >1">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <div class="btn-footer">
            <v-btn color="green" dark @click="addUploadData">
                <v-icon >mdi-plus</v-icon>
            </v-btn>
            <v-btn

                    @click="manualUpload"
                    class="ma-2 btn-upload"
                    color="purple darken-4"
                    dark
                    outlined
            >
                Upload
                <v-icon light>mdi-cloud-upload</v-icon>
            </v-btn>
        </div>

    </v-card>
</template>

<script>
    // import axios from "axios";
    import axios from "axios";

    export default {
        name: "UploadModel",
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                uploadData: [{
                    FileName: '',
                    File: ''
                }],
                servicesData: {
                    data: {},
                    message: "",
                    color: "",
                    popup:false
                },
                labelDropdownUpload:[]
            };
        },

        mounted() {
            this.formData()
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {

            formData(){
                axios.get('Division/DDLDivisionListForManualAsync').then(response=>{
                    this.labelDropdownUpload = response.data
                })
            },
            dialogueClose() {
                this.$emit("formResponse", this.servicesData);
            },
            manualUpload() {
                var form = new FormData();
                this.uploadData.forEach((response,index)=>{
                    form.append("ManualList["+index+"].FileName", response.FileName);
                    form.append("ManualList["+index+"].File", response.File);
                })

                console.log(form)
                if (this.uploadData) {
                    axios.post("Upload/Upload", form).then(response => {
                        console.log(response)
                        this.servicesData.data = response.data
                        this.servicesData.message = "Manual Uploaded Succcessfully"
                        this.servicesData.color = "success"
                        this.servicesData.popup = true
                        this.dialogueClose()
                    })
                        .catch(e => {
                            console.log('error', e)
                        })
                }
            },
            addUploadData() {
                this.uploadData.push({
                    FileName: '',
                    File: ''
                })
            },
            removeUploadData(index) {
                this.uploadData.splice(index, 1)
            }
        }
    };
</script>

<style lang="scss" scoped>
    .btn-footer {
        text-align: right !important;
    }

    .col-border {
        background-color: #BBDEFB;
    }
</style>