<script>
    import { Line, mixins } from 'vue-chartjs'
    const { reactiveProp } = mixins
    export default {
        extends: Line,
        props: {
          chartData: {
              required: true,
              type: Object
          }  ,
          chartOptions: {
              required: true,
              type: Object
          }  ,
        },
 /*       computed: {
            chartData: function() {
                return this.data;
            },
            chartOptions: function() {
                return this.options;
            }
        },*/
        methods: {
            renderLineChart: function() {
                this.renderChart(this.chartData,this.chartOptions)
            }
        },
        mixins: [ reactiveProp ],
        mounted() {
            // this.chartData is created in the mixin.
            // If you want to pass options please create a local options object
            this.renderChart(this.chartData,this.chartOptions)
            window.dispatchEvent(new Event('resize'));
        },
       /* watch: {
            data: function() {
                this._chart.destroy();
                //this.renderChart(this.data, this.options);
                this.renderLineChart();
            }
        }*/
    };
</script>